import React, { useRef, useState } from "react"
import BannerHeader from "../components/bannerHeader"
import "../static/style/pageStyle/home-page.scss"
import "../static/style/globalCss/platform-page.scss"
import "../static/style/globalCss/common.scss"
import "../static/style/globalCss/style.scss"
import Parser from "html-react-parser"


import { Link, useStaticQuery } from "gatsby"
import { homePageData } from "../services/helperFunctions.js"
import { Helmet } from "react-helmet"
// import pryzmVideo from "../static/images/video/Pryzm-video.mp4";
import GetStartedModal from "../components/getStaredModal"
import Underline from "../components/underline"
import FinancialServiceSlider from "../components/financial-service-slider"
import Loadable from "@loadable/component"

import pryzmVideoBanner from "../static/images/pryzm-video-banner.jpg"
import Slider from "react-slick"
import BookDemo from "../components/book-demo"
import BlackBtn from "../components/black-btn.js"
import pdf from "../static/pdfs/Pryzm_Brochure.pdf"
import DownloadBtn from "../components/download-btn.js"
const LoadableCards = Loadable(() => import("../components/cards"))


const Homepage = props => {
    const strapiData = useStaticQuery(
        graphql`
      {
        allPryzmHome {
            nodes {
              pryzmHome {
                journey {
                  attributes {
                    cmpName
                    heading
                    subheading
                    imageUrl
                    dynamicCmp {
                      iconUrl
                      cardHeading
                      content
                      heading
                      paragraph
                    }
                  }
                }
              }
            }
          }
      }
    `
    )
    const [isVideoVisible, setIsVideoVisible] = useState(false);

    const handlePlayVideo = () => {
        setIsVideoVisible(true);
    };

    const {
        banner,
        section1,
        section2,
        section3,
        section4,
        section5,
        section6,
        section7,
        testimonialSlider,
        section8,
        section9
    } = homePageData(strapiData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleIsOpenModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const [isOpen, setIsOpen] = useState(false);

    const downloadLinkRef = useRef(null);

    const handleDownloadAndNavigate = (e) => {
        e.preventDefault();

        if (downloadLinkRef.current) {
            downloadLinkRef.current.href = pdf;
            downloadLinkRef.current.download = 'Pryzm_Brochure.pdf';
            downloadLinkRef.current.click();
        }
        window.open(pdf, '_blank');
    };


    const title = "Data Reliability & Observability Platform | PRYZM";
    const metsDescription = "PRYZM provides top-tier Enterprise Data Reliability & Observability, ensuring precise and reliable data. The ultimate solution for data excellence.";
    return (
        <>
            <div className={`platform-v3 home-page empower-pryzm`}>
                <Helmet
                    htmlAttributes={{
                        lang: "en",
                    }}
                >        <title>{title}</title>
                    <meta name="description" content={metsDescription} />
                </Helmet>
                <div className="page-content">
                    <div className="banner">
                        <div className="page-wrapper">
                            {/* <div className="heading">
                                <BannerHeader line="single-line" headingType="bannerTitle" header={banner.heading} />
                            </div> */}
                            <div className="main-container">
                                <div className="video">
                                    <div className="video-container">
                                        {!isVideoVisible && (
                                            <div className="thumbnail-container" onClick={handlePlayVideo}>
                                                <img
                                                    src={pryzmVideoBanner} // Replace with your image URL
                                                    alt="Custom Video Thumbnail"
                                                    className="thumbnail-image"
                                                />
                                                <div className="play-button"></div> {/* Custom play button */}
                                            </div>
                                        )}
                                        {isVideoVisible && (
                                            <div className="iframe-container">
                                                <iframe
                                                    src="https://www.youtube.com/embed/yalyBYQ-pyA?rel=0&si=QMTdG5ONyFE32qV9&autoplay=1" // autoplay once the video starts
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        )}
                                    </div>

                                </div>
                                <div className="text-content">
                                    <p className="two-line">{Parser(banner.subheading)}</p>
                                    <p className="paragraph">Stay ahead of data issues with end-to-end Data Observability, ensuring precision and trust in every data point. </p>
                                    <div className="btn-area">
                                        <a
                                            href={pdf}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={handleDownloadAndNavigate}
                                        >
                                            <DownloadBtn text="Download Brochure" pdf={"Pryzm_Brochure.pdf"} />
                                        </a>
                                        <a ref={downloadLinkRef} style={{ display: 'none' }}></a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* ******************************************* */}
                    <div className="section-02">
                        <div className="page-wrapper">
                            {/* <div className="btn-area">
                                <div className="btn-area">
                                    <BookDemo
                                        content={"Book A Demo"}
                                        routeTo={"demo-page"}
                                    />
                                </div>
                            </div> */}
                            <div className="data-image">
                                <img
                                    src={`${process.env.STRAPI_URL + section8.imageUrl}`} />
                            </div>
                        </div>
                    </div>
                    <div className="section-03">
                        <div className="page-wrapper">
                            <div className="main-container">
                                <div className="single-content">
                                    <h2>{Parser(section8.heading)}</h2>
                                </div>
                                {/* <div className="double-content">
                                    <div className="double-content-first">
                                        <h2>{section8.subheading}</h2>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <Underline />
                    {/* Key Benefits */}
                    <div className="key-benefits">
                        <div className="page-wrapper">
                            <div className="heading">
                                <p>{section1.heading}</p>
                            </div>
                            <div className="para">
                                <p>{Parser(section1.subheading)}</p>
                            </div>
                            <div className="cards">
                                {section1.dynamicCmp.map((item, index) => (
                                    <div className="card-child">
                                        <div className="card-image">
                                            {/* <p>{item.cardHeading}</p> */}
                                            <img src={`${process.env.STRAPI_URL + item.iconUrl}`} />
                                        </div>
                                        <div className="content">
                                            <p>{Parser(item.content)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="btn-area">
                                <BookDemo
                                    content={"Know More"}
                                    routeTo="/benefits"
                                />
                            </div>
                        </div>
                    </div>
                    {/* data reliability  */}
                    {/* <div className="data-reliability">
                        <div className="page-wrapper">
                            <div className="text-container">
                                <p>{Parser(section2.heading)}</p>
                            </div>
                            <div className="btn-area">
                                <BookDemo
                                    content={"Book A Demo"}
                                    routeTo="/demo-page"
                                />
                            </div>
                        </div>

                    </div> */}

                    <div className="single-pane">
                        <div className="page-wrapper">
                            <div className="heading">
                                <p>{section3.heading}</p>
                            </div>
                            <div className="para">
                                <p>{section3.subheading}</p>
                            </div>
                            <div className="cards">
                                {section3.dynamicCmp.map((item, idx) => (
                                    <div className="card-child" key={idx}>
                                        <div class="icon">
                                            <img src={`${process.env.STRAPI_URL + item.iconUrl}`} alt={item.cardHeading} />
                                        </div>
                                        <h3>{item.cardHeading}</h3>
                                        <p>{item.content}</p>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    {/* <Underline /> */}
                    <Underline />
                    <div className="about-us">
                        <div className="page-wrapper">
                            <div className="heading">
                                <h3>{section6.heading}</h3>
                            </div>
                            <div className="para">
                                <p>{Parser(section6.subheading)}</p>
                            </div>
                            <div className="btn-area">
                                <BookDemo
                                    content={"Know More"}
                                    routeTo="https://lumiq.ai/"
                                    target={"_blank"}
                                />
                            </div>
                        </div>
                    </div>
                    <Underline />
                    <FinancialServiceSlider />
                    <Underline />
                    <div className="client-trust">
                        <div className="page-wrapper">
                            <div className="main-container" id="customerSuccess">
                                <BannerHeader header={section7.heading} line={"single-line"} />
                                <div className="card-container">
                                    <div className="testimonialDesktop" aria-label="slider">
                                        <Slider {...testimonialSlider}>
                                            {section7.dynamicCmp.map((item, i) => (
                                                <LoadableCards
                                                    key={i}
                                                    cardData={item.paragraph}
                                                    cardClient={item.heading}
                                                />
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                                {/* <div className="card-container-mobile ">
                                    <div className="testimonialDesktop">
                                        {section7.dynamicCmp.map((item, i) => (
                                            <LoadableCardsMobile
                                                key={i}
                                                cardData={item.paragraph}
                                                cardClient={item.heading}
                                            />
                                        ))}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <Underline /> */}
                    <div className="CTA">
                        <div className="page-wrapper">
                            <div className="text">
                                <p>{Parser(section9.heading)}</p>
                            </div>
                            <div className="btn-area">
                                <GetStartedModal content={"Contact Us"} isOpen={isOpen} setIsOpen={setIsOpen} isModalOpen={isModalOpen} handleIsOpenModal={handleIsOpenModal} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Homepage
